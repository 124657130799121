import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { d3Theme } from "../../Theme";
import styled from "@emotion/styled";
import EventModal from "./EventModal";
import { Translator, Translate } from "react-auto-translate";
import { useLocation } from "react-router-dom";

export default function EventsCalendar2(props) {
  const [events, setEvents] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const setModalClose = () => setModalOpen(false);
  const [modalContent, setModalContent] = useState();
  const [calendarView, setCalendarView] = useState("dayGridMonth");
  const [referEvent, setReferEvent] = useState();
  let eventState = useLocation();

  const defaultOptions = {
    header: {
      weekday: "long",
    },
    footer: {
      month: "short",
    },
    value: {
      day: "2-digit",
    },
    year: {
      year: "numeric",
    },
    locale: [],
  };

  function formatDate(date, locale, formatOptions) {
    return date.toLocaleDateString("en-US", formatOptions);
  }

  // const CalendarRef = useRef();
  useEffect(() => {
    if (eventState && eventState.state && eventState.state.id && events) {
      // construct a modal using the id
      var selectedEvent = events.filter((e) => e.id === eventState.state.id);
      setModalContent(selectedEvent[0]);
      setModalOpen(true);
    }
  }, [eventState, events]);

  useEffect(() => {
    axios
      .get("api/events/")
      .then((res) => {
        // console.log(res.data);
        const formattedEvents = res.data.map((ev) =>
          ev.time_period_start.map((ea, i) => ({
            id: ev.id,
            title: ev.title,
            allDay: ev.is_all_day,
            start: new Date([ev.event_date, ea].join(" ").replace(/-/g,'/')),
            end: new Date([ev.event_date, ev.time_period_end[i]].join(" ").replace(/-/g,'/')),
            location: ev.location,
            content: ev.content,
            image: ev.image,
            event_start: new Date([ev.event_date, ea].join(" ").replace(/-/g,'/')),
            event_end: new Date(
              [ev.event_date, ev.time_period_end[i]].join(" ").replace(/-/g,'/')
            ),
            event_title: ev.title,
            event_all_day: ev.is_all_day,
          }))
        );

        // console.log(formattedEvents);
        setEvents([].concat(...formattedEvents));
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);
  const langXWalk = {
    en: "en",
    es: "es",
    "zh-CN": "zh",
    bn: "bn",
    ru: "ru",
    ur: "ur",
    ar: "ar",
    ht: "ht",
    ko: "ko",
    fr: "fr",
  };

  const buttonTextLang = {
    en: {
      today: "today",
      month: "month",
      week: "week",
      day: "day",
      list: "list",
    },
    es: {
      today: "hoy dia",
      month: "mes",
      week: "semana",
      day: "día",
      list: "lista",
    },
    "zh-CN": {
      today: "今天",
      month: "月",
      week: "星期",
      day: "天",
      list: "列表",
    },
    bn: {
      today: "আজ",
      month: "মাস",
      week: "সপ্তাহ",
      day: "দিন",
      list: "তালিকা",
    },
    ru: {
      today: "сегодня",
      month: "месяц",
      week: "неделя",
      day: "день",
      list: "список",
    },
    ur: {
      today: "آج",
      month: "مہینہ",
      week: "ہفتہ",
      day: "دن",
      list: "فہرست",
    },
    ar: {
      today: "اليوم",
      month: "شهر",
      week: "أسبوع",
      day: "يوم",
      list: "قائمة",
    },
    ht: {
      today: "jodi a",
      month: "mwa",
      week: "semèn",
      day: "jou",
      list: "lis",
    },
    ko: {
      today: "오늘",
      month: "월",
      week: "주",
      day: "낮",
      list: "목록",
    },
    fr: {
      today: "aujourd'hui",
      month: "mois",
      week: "semaine",
      day: "jour",
      list: "liste",
    },
  };
  const xwalk = {
    month: ["fc-dayGridMonth-view", "fc-view", "fc-daygrid"],
    week: ["fc-timeGridWeek-view", "fc-view", "fc-timegrid"],
    day: ["fc-timeGridDay-view", "fc-view", "fc-timegrid"],
  };

  const handleEventClick = (e) => {
    changeView(e.view.type);
    setModalContent(e.event._def.extendedProps);
    setModalOpen(true);
  };
  const calendarRef = useRef();

  const changeView = (view) => {
    // console.log(view);
    const API = getApi();
    // setCalendarView(view);
    API && API.changeView(view);
    // console.log(API);
    // console.log(view);
  };

  const getApi = () => {
    const { current: calendarDom } = calendarRef;
    console.log(calendarDom);
    return calendarDom ? calendarDom.getApi() : null;
  };

  const calHTML = document.getElementsByClassName("fc-view");

  const StyleWrapper = styled.div`
    .fc {
      font-family: "Hass Grot Disp 55 Roman";
    }
    .fc-day-other {
      background-color: #fff !important;
    }
    .fc-daygrid-day {
      background-color: #fcdadb;
    }
    .fc-button.fc-prev-button,
    .fc-button.fc-next-button,
    .fc-button.fc-button-primary {
      background: #00567c;

  `;

  const calendar = (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin]}
      events={events}
      locale={langXWalk[props.lang]}
      headerToolbar={{
        left: "title",
        center: "prev,next today",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      initialView="dayGridMonth"
      eventClick={handleEventClick}
      eventColor={"#FE454A"}
      buttonText={buttonTextLang[props.lang]}
      ref={calendarRef}
    />
  );
  return (
    <div>
      {events ? (
        <div>
          {modalContent && modalOpen ? (
            <EventModal
              open={modalOpen}
              setClose={setModalClose}
              modalContent={modalContent}
            />
          ) : null}

          <StyleWrapper>{calendar}</StyleWrapper>
        </div>
      ) : null}
    </div>
  );
}
