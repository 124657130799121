import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Grid,
  Button,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import InputEmailField from "./EmailSignup";
import { Translate } from "react-auto-translate";

export default function FormParent(props) {
  const [showItems, setShowItems] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [affil, setAffil] = useState([]);
  const [warning, setWarning] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [checkValid, setCheckIsValid] = useState(false);
  const [formError, setFormError] = useState(false);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function handleSubmitForm(event) {
    setCheckIsValid(true);
    if (validateEmail(email) && firstName.length > 0 && lastName.length > 0) {
      axios
        .post("api/email/", {
          email: email,
          firstName: firstName,
          lastName: lastName,
          affiliation: affil,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
      setShowItems(false);
      setShowWarning(false);
    } else {
      setFormError(true);
      setShowWarning(true);
    }
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setShowWarning(false);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setShowWarning(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setShowWarning(false);
  };

  const handleAffiliationChange = (v) => {
    setAffil(v);
    setShowWarning(false);
  };

  const affiliations = [
    { label: "Student" },
    { label: "Parent" },
    { label: "Teacher" },
    { label: "Community Member" },
  ];

  return (
    <>
      <Grid container spacing={2} id="form-parent-root">
        <Grid item sm={8}>
          {showItems === true ? (
            <>
              <Grid container spacing={1} display={"flex"}>
                <Grid item xs={12} display={"inline-grid"}>
                  <TextField
                    placeholder={"Email Address"}
                    sx={{ backgroundColor: "white" }}
                    onChange={handleEmailChange}
                    error={
                      formError &&
                      (email === undefined || !validateEmail(email))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} display={"inline-grid"}>
                  <TextField
                    placeholder={"First Name"}
                    sx={{ backgroundColor: "white" }}
                    onChange={handleFirstNameChange}
                    required={true}
                    error={formError && firstName.length === 0}
                  />
                </Grid>
                <Grid item xs={12} sm={6} display={"inline-grid"}>
                  <TextField
                    placeholder={"Last Name"}
                    sx={{ backgroundColor: "white" }}
                    onChange={handleLastNameChange}
                    error={formError && lastName.length === 0}
                  />
                </Grid>
                <Grid item xs={12} display={"inline-grid"}>
                  <Autocomplete
                    multiple
                    options={affiliations.map((a) => a.label)}
                    sx={{ backgroundColor: "white" }}
                    onChange={(e, v) => handleAffiliationChange(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Affiliation with D3"
                        placeholder=""
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>
              <Translate> Thank you</Translate>
            </Typography>
          )}
        </Grid>
        <Grid item sm={4} align="left">
          {showItems === true ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmitForm}
            >
              <Translate> Submit</Translate>
            </Button>
          ) : (
            <div></div>
          )}
        </Grid>
        {showWarning ? (
          <Grid item>
            <Typography color="red">
              <Translate>
                Please provide a valid input for the fields above.{" "}
              </Translate>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
