import { useState, useEffect } from "react";
import { Box, Typography, Modal, Grid } from "@mui/material";
import Parse from "../utils/HTMLParser";
import { Translator, Translate } from "react-auto-translate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70vw", md: "50vw" },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowX: "scroll",
  maxHeight: "70vh",
};

export default function EventModal(props) {
  const modalContent = props.modalContent;
  const dateTimeStart = new Date(modalContent.event_start.replace(/-/g,'/'));
  const dateTimeEnd = new Date(modalContent.event_end.replace(/-/g,'/'));
  const timeStart = dateTimeStart.toLocaleTimeString();
  const timeEnd = dateTimeEnd.toLocaleTimeString();

  return (
    <>
      {props ? (
        <Modal open={props.open} onClose={props.setClose}>
          {modalContent ? (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <Translate>{modalContent.event_title} </Translate>
              </Typography>
              <Parse html={modalContent.location} />
              <Grid container display="">
                <Grid item xs={6}>
                  <Typography>
                    {modalContent.event_all_day ? (
                      <Translate>All Day</Translate>
                    ) : (
                      <Translate>
                        Time: {timeStart} - {timeEnd}{" "}
                      </Translate>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0} sx={{ paddingTop: "1em" }}>
                {modalContent.image ? (
                  <Grid item xs={12} sm={6}>
                    <Box
                      component="img"
                      src={`https://doe-d3.s3.amazonaws.com/images/${modalContent.image}`}
                      sx={{ width: "90%" }}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={6}>
                  <Parse html={modalContent.content} />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
}
