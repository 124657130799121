import { Grid, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import scrollOffset from "../../utils/ScrollOffset";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "../../components/Stylesheet.module.css";

export default function D3() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checked, setChecked] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition < scrollOffset) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [scrollPosition]);
  return (
    <div>
      {checked ? (
        <Box sx={{ marginLeft: "2em", padding: 0 }}>
          <Typography
            className={style.disableTextSelection}
            variant="h1"
            sx={{
              fontSize: { xs: "10em", sm: "20em", md: "30em" },
              position: "absolute",
              fontWeight: "700",
              color: "#fe454a",
              // bottom: { xs: -40, sm: -80, md: -120 },
              // keeping the bottom alignment in case we want to revert back
              // top: { xs: 10, sm: 10, md: 10 },
              // left: { xs: -40, sm: -70, md: -90 },
              zIndex: "1000",
            }}
          >
            D3
          </Typography>
        </Box>
      ) : null}
    </div>
  );
}
