import { useState, useEffect, useContext } from "react";
import { TextField, Autocomplete, Typography } from "@mui/material";
import { MapContext } from "../../views/schools";
import { Translator, Translate } from "react-auto-translate";

export default function SchoolSearchComboBox(props) {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState();
  const [label, setLabel] = useState("Search for a school");
  const ctx = useContext(MapContext);
  const schoolData = ctx.schoolData;
  const globalProps = props.globalProps;

  useEffect(() => {
    setLabel(<Translate>{label}</Translate>);
  }, [globalProps.lang]);

  useEffect(() => {
    if (schoolData) {
      const opts = schoolData.map((s, i) => ({
        label: `${s.properties.ps} ${s.properties.name}`,
        id: `${s.properties.dbn}`,
      }));
      setOptions(opts);
    }
  }, [schoolData]);

  useEffect(() => {
    props.handleSchoolSelect(value);
  }, [value]);

  return (
    <div>
      {options ? (
        <>
          <Typography
            variant="h4"
            sx={{
              color: "#FE454A",
              textAlign: { sm: "center", md: "left" },
            }}
          >
            <Translate>{"Search for a School"} </Translate>
          </Typography>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="school-search-combobox"
            options={options}
            sx={{
              width: { xs: "100%", sm: 280 },
              height: "34.5px",
              alignItems: "center",
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
            size="small"
          />
        </>
      ) : null}
    </div>
  );
}
