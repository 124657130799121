import { useRef, useEffect, useState, useContext } from "react";
import { MapContext } from "../../views/schools";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import FilterButtons from "./FilterButtons";
import SchoolSearchComboBox from "./SchoolSearchComboBox";
import { Translator, Translate } from "react-auto-translate";
import useWindowDimensions from "../utils/UseWindowDimensions";
export default function SchoolSearch(props) {
  const fab = props.fab;
  const globalProps = props.globalProps;
  const ctx = useContext(MapContext);
  const [levels, setLevels] = useState([]);
  const { height, width } = useWindowDimensions();
  const [schoolQuals, setSchoolQuals] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();

  const handleSchoolSelect = (e) => {
    // fires when a school is chosen from the dropdown;
    // reposition map on school.
    if (e) {
      setSelectedSchool(e.id);
      const sch = schoolLookup(e.id, ctx.schoolData);
      ctx.setSchool(sch.properties);
      ctx.map.getSource("selectedSchool").setData(sch);
    } else {
      setSelectedSchool();
      // ctx.map.getSource("selectedSchool").setData();
    }
  };

  function schoolLookup(school, schools) {
    const sch = schools.filter((i) => i.properties.dbn === school);
    return sch[0];
  }

  const handleLevelClick = (e) => {
    if (levels.some((lev) => lev === e.target.value)) {
      var lvls = levels.filter((i) => i !== e.target.value);
      setLevels(lvls);
    } else {
      setLevels([...levels, e.target.value]);
    }
    // handle case if levels is empty - seems a bit buggy now
  };

  const handleQualsClick = (e) => {
    if (schoolQuals.some((lev) => lev === e.target.value)) {
      var lvls = schoolQuals.filter((i) => i !== e.target.value);
      setSchoolQuals(lvls);
      console.log(`removing ${e.target.value} from clicked`);
    } else {
      setSchoolQuals([...schoolQuals, e.target.value]);
      console.log(`adding ${e.target.value} to clicked`);
    }
  };

  useEffect(() => {
    // filters based on school level;
    // think about how to handle multiple filters at once
    var formattedQuals = schoolQuals.map((q) => [
      "==",
      ["get", Object.keys(ctx.quals).find((k) => ctx.quals[k] === q)],
      true,
    ]);
    if (ctx.map) {
      if (levels.length > 0 && formattedQuals.length === 0) {
        console.log(levels);
        ctx.map.setFilter("schools", [
          "match",
          ["get", "sch_type"],
          levels,
          true,
          false,
        ]);
      } else if (formattedQuals.length > 0 && levels.length === 0) {
        ctx.map.setFilter("schools", ["all", ...formattedQuals]);
      } else if (levels.length > 0 && formattedQuals.length > 0) {
        ctx.map.setFilter("schools", [
          "all",
          ["match", ["get", "sch_type"], levels, true, false],
          ...formattedQuals,
        ]);
      } else {
        ctx.map.setFilter("schools", null);
      }
      // });
    }
  }, [levels, schoolQuals]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            textAlign: { sm: "center", md: "left" },
            paddingTop: "2em",
            paddingLeft: { sm: "0.5em", lg: "2em", xl: "14em" },
            paddingRight: { sx: "2em" },
          }}
        >
          <Grid item>
            <Typography variant="h2">
              <Translate>Find A School </Translate>
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={1}
              sx={{
                direction: { xs: "column", sm: "column", md: "row" },
                border: "thick",
                borderColor: "blue",
                // backgroundColor: {
                //   xs: "pink",
                //   sm: "blue",
                //   md: "purple",
                //   lg: "green",
                // },
              }}
              direction={!fab ? "row" : "column"}
              id="all-search-container"
            >
              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  textAlign: { sm: "center", md: "left" },
                  alignItems: { sm: "center" },
                }}
              >
                <SchoolSearchComboBox
                  handleSchoolSelect={handleSchoolSelect}
                  globalProps={globalProps}
                />
              </Grid>
              <Grid item xs={0} sm={0} md={1} lg={1} />
              <Grid item xs={12} sm={8} lg={7}>
                <Grid
                  container
                  direction={width > 1300 || !fab ? "row" : "column"}
                >
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      textAlign: { sm: "center", md: "left" },
                      paddingLeft: "0.1em",
                      paddingRight: "0.1em",
                    }}
                  >
                    <FilterButtons
                      title={"Grades"}
                      handleClick={handleLevelClick}
                      choices={ctx.schoolLevels}
                      selected={levels}
                      setSelected={setLevels}
                      group={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      textAlign: { sm: "center", md: "left" },
                      paddingLeft: "0.1em",
                      paddingRight: "0.1em",
                    }}
                  >
                    <FilterButtons
                      title={"Programs"}
                      handleClick={handleQualsClick}
                      choices={ctx.quals}
                      selected={schoolQuals}
                      setSelected={setSchoolQuals}
                      group={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
