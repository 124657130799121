import { CardActions, Grid } from "@mui/material";
import Parse from "../utils/HTMLParser";
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  IconButton,
  Collapse,
  Link,
} from "@mui/material";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import TeamMemberModal from "./TeamMemberModal";
import { Translator, Translate } from "react-auto-translate";
import { Container } from "@mui/system";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TeamMember(props) {
  const member = props.member;
  const [expanded, setExpanded] = useState(false);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleSetOpen = () => {
    console.log("clicked me");
    setOpen(true);
  };
  useEffect(() => {
    if (member.image) {
      setImage(`https://doe-d3.s3.amazonaws.com/images/${member.image}`);
    } else
      setImage(`https://doe-d3.s3.amazonaws.com/images/headshot-white.png`);
  }, []);
  const styles = {
    media: {
      height: { xs: "250px", md: "500px" },
      //   paddingTop: "56.25%", // 16:9
    },
    card: {
      position: "relative",
      margin: "0.5em",
    },
    overlay: {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      color: "white",
      padding: "0.25em",
      backgroundColor: "#00567C",
    },
  };
  return (
    <>
      {member.wide === true ? (
        <Grid
          container
          spacing={6}
          id="team-member-grid-container"
          sx={{ marginLeft: { xs: "0" }, width: { xs: "100vw" }, padding: 0 }}
          direction="row"
        >
          <Grid
            item
            xs={12}
            sm={6}
            id="wide-img-grid-item"
            sx={{
              paddingLeft: { xs: "0px!important", sm: "0px!important" },
              padding: "0px",
            }}
          >
            <Box
              component="img"
              src={image}
              sx={{
                position: "relative",
                margin: "auto",
                width: "100%",
                maxHeight: "500px",
                objectFit: "cover",
                objectPosition: "100% 0%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              textAlign: "left",
              paddingLeft: { xs: "0px!important", sm: "48px!important" },
            }}
          >
            <Typography variant="h3" sx={{ color: "#FE454A" }}>
              {member.title}
              <Typography variant="h3" sx={{ color: "#000" }}>
                {member.name}{" "}
              </Typography>
            </Typography>
            <Parse html={member.description} />

            <Grid
              container
              spacing={2}
              sx={{ textAlign: "left", alignItems: "center", py: 1 }}
            >
              <Grid item xs={1}>
                <EmailIcon sx={{ color: "#00567C" }} />
              </Grid>

              <Grid item xs={11}>
                <Typography varian="body2">
                  <Link>{member.email_address}</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} sm={4}>
          <Card
            sx={{ mr: "1em", my: "2em", textAlign: "left" }}
            elevation={0}
            onClick={handleSetOpen}
          >
            <CardActionArea onClick={handleSetOpen}>
              <TeamMemberModal
                name={member.name}
                description={member.description}
                title={member.title}
                open={open}
              />
              <CardMedia sx={styles.media} image={image}></CardMedia>
              <CardContent>
                <Typography variant="h3" sx={{ color: "#000" }}>
                  {member.name}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#FE454A", fontSize: "1.5rem" }}
                >
                  {member.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
    </>
  );
}
