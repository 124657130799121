import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Parse from "../utils/HTMLParser";
import { Translator, Translate } from "react-auto-translate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70vw", md: "50vw" },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll !important",
  maxHeight: "80vh",
};

export default function TeamMemberModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const memberName = props.name;
  const description = props.description;
  const membertitle = props.title;

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      ></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ yOverflow: "scroll" }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            sx={{ color: "#000" }}
            component="h2"
          >
            {memberName}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h5"
            sx={{ color: "#FE454A", fontSize: "1.5rem", pb: "0.5em" }}
            component="h2"
          >
            {membertitle}
          </Typography>

          <Parse html={description} />
        </Box>
      </Modal>
    </div>
  );
}
