import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import { Box, Dialog } from "@mui/material";
import { Fab } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MapContext } from "../../views/schools";
import SchoolSearch from "./SchoolSearch";

function MapDialog(props) {
  const { onClose, open } = props;
  console.log(props);
  const globalProps = props.settings;
  return (
    <Dialog onClose={onClose} open={open} sx={{ maxHeight: "90%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "80vh",
          padding: "1em",
        }}
      >
        <SchoolSearch globalProps={globalProps} fab={true} />
      </Box>
    </Dialog>
  );
}

MapDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function MapSelectFab(props) {
  const globalProps = props.globalProps;
  const lang = globalProps.lang;
  // console.log(props);
  // const userSettings = props.settings;
  const [open, setOpen] = useState(false);
  const ctx = useContext(MapContext);

  useEffect(() => {
    if (ctx.school) {
      setOpen(false);
    }
  }, [ctx.school]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="sidebar"
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          top: "66px",
          right: "10px",
          color: "#ffffff",
        }}
      >
        <InfoOutlinedIcon />
      </Fab>
      <MapDialog
        open={open}
        onClose={handleClose}
        lang={lang}
        settings={globalProps}
      />
    </div>
  );
}
