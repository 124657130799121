import React, { useState } from "react";
import {
  Container,
  Typography,
  Toolbar,
  Box,
  AppBar,
  MenuItem,
  Menu,
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Slide from "@mui/material/Slide";
import LanguageSelector from "./utils/LanguageSelector";
import useWindowDimensions from "./utils/UseWindowDimensions";
import { Translate, Translator } from "react-auto-translate/lib/commonjs";
import { cacheProvider } from "../utils/LangCacheProvider";

const pages = ["home", "about", "schools", "admissions", "news-events"];

export default function MainHeader(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { height, width } = useWindowDimensions();
  // const globalProps = props.globalProps;
  // console.log(props);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  // if (!globalProps.lang) return;
  return (
    <Slide direction="down" in={props.checked} mountOnEnter>
      <AppBar
        position="fixed"
        sx={{ marginBottom: "20px", backgroundColor: "#fe454a" }}
      >
        <Translator
          to={props.lang}
          from="en"
          googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
          cacheProvider={cacheProvider}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h1"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  // fontWeight: 700,
                  fontSize: "4em",
                  letterSpacing: ".3rem",
                  color: "#ffffff",
                  textDecoration: "none",
                  margin: "0.1em",
                }}
              >
                D3
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Link
                        to={`/${page}`}
                        style={{ color: "#00567C", textDecoration: "none" }}
                      >
                        <Typography textAlign="center">
                          <Translate>
                            {page.toUpperCase().replace("-", " & ")}
                          </Translate>
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h3"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,

                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                D3
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    <Typography variant="h4">
                      <Link
                        to={`/${page}`}
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        <Translate>
                          {page.toUpperCase().replace("-", " & ")}
                        </Translate>
                      </Link>
                    </Typography>
                  </Button>
                ))}
              </Box>
              <Box>
                <LanguageSelector {...props} />
              </Box>
            </Toolbar>
          </Container>
        </Translator>
      </AppBar>
    </Slide>
  );
}
