import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import axios from "axios";
import Parse from "../components/utils/HTMLParser";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import MainHeader from "../components/AppBar";
import { Translator, Translate } from "react-auto-translate";

function NewsItem(props) {
  // console.log(props);
  const globalProps = props.globalProps;
  const lang = globalProps.lang;
  const [date, setDate] = useState();
  const [image, setImage] = useState();
  const [content, setContent] = useState();
  const [title, setTitle] = useState();

  let { slug } = useParams();

  useEffect(() => {
    axios
      .get(`/api/news/${slug}`)
      .then((res) => {
        const parser = new DOMParser().parseFromString(
          res.data[0].content,
          "text/html"
        );
        var h = [...parser.body.children].map((el) => el.outerHTML);
        setContent(h);
        const viewDate = new Date(
          res.data[0].publish_date.replace(/-/g,'/')
        ).toLocaleDateString();
        setDate(viewDate);
        setTitle(res.data[0].title);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
      >
        {content ? (
          <Box>
            <MainHeader {...globalProps} checked={true} />
            <Container
              maxWidth={"lg"}
              sx={{ paddingBottom: "3em", paddingTop: "3em", marginTop: "4em" }}
            >
              <Grid container sx={{ textAlign: "left" }} direction={"column"}>
                <Grid item>
                  <Typography variant="h2">{title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ color: "#000", pb: "1em" }}>
                    <Translate>
                      Published {"   "}
                      {date}
                    </Translate>
                  </Typography>
                </Grid>
                <Grid item>
                  {content.map((b, i) => (
                    <Parse html={b} />
                  ))}
                </Grid>
                <Grid item my={3}>
                  <Link to="/news-events">
                    <Button variant="contained">
                      <Translate>Back to News and Events</Translate>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Box>
        ) : null}
      </Translator>
    </div>
  );
}
export { NewsItem };
