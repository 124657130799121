import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { Translator, Translate } from "react-auto-translate";
export default function EventList() {
  const [events, setEvents] = useState();

  useEffect(() => {
    axios.get("api/upcoming_events/5").then((res) => {
      setEvents(res.data);
      // console.log(res.data);
    });
  }, []);
  return (
    <div>
      <Typography variant="h2" sx={{ textAlign: "left" }}>
        <Translate> Upcoming Events</Translate>
      </Typography>
      {events ? (
        <Grid container direction="column" spacing={0}>
          {events.map((event, i) => (
            <EventCard event={event} key={i} />
          ))}
        </Grid>
      ) : null}
    </div>
  );
}
