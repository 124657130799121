import { Grid, Typography, Paper, Box, Divider } from "@mui/material";
import k8 from "../../icons/k8.png";
import ps from "../../icons/ps.png";
import ms from "../../icons/ms.png";
import hs from "../../icons/hs.png";
import k12 from "../../icons/k_12.png";

export default function SchoolLegend() {
  const levels = [
    { level: "K-8", icon: k8 },
    { level: "PS", icon: ps },
    { level: "MS", icon: ms },
    { level: "6-12", icon: hs },
    { level: "K-12", icon: k12 },
  ];

  return (
    <>
      <Paper
        sx={{
          bottom: { xs: "75px", md: "10px" },
          right: "10px",
          position: "fixed",
          zIndex: 3000,
          padding: "1.25rem",
          textAlign: "center",
        }}
      >
        <Grid container direction={"column"} spacing={1}>
          <Typography fontWeight="700">Legend</Typography>
          {levels.map((lev, i) => (
            <>
              <Grid item key={`level-grid-${i}`}>
                <Grid
                  container
                  direction={"row"}
                  sx={{ alignItems: "center" }}
                  key={`level-grid-container-${i}`}
                >
                  <Grid
                    item
                    key={`level-grid-row-${i}`}
                    sx={{
                      padding: "3px",
                      display: "inline-block",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={lev.icon}
                      key={`level-grid-icon-${i}-img`}
                    />
                  </Grid>
                  <Grid item key={`level-grid-row-label-${i}`}>
                    <Typography key={`level-grid-text-${i}`}>
                      {lev.level}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}

          <Grid item key={`level-grid-nond3`}>
            <Grid
              container
              sx={{ justifyContent: "center", placeContent: "center" }}
              key={`level-grid-nond3-container`}
              spacing={0}
            >
              <Grid
                item
                key={`level-grid-nond3-icon`}
                sx={{
                  backgroundColor: "#b5b5b5",
                  padding: "7.5px",
                  margin: "5px",
                  display: "inline-block",
                  alignItems: "center",
                  borderRadius: "2rem",
                }}
              />
              <Grid item>
                <Typography>Non-D3</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
