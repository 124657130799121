import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { NewsItem } from "./views/newsItem";
import Home from "./views/home";
import About from "./views/about";
import Schools from "./views/schools";
import Admissions from "./views/admissions";
import Vision from "./views/vision";
import Academics from "./views/academics";
import NewsEvents from "./views/newsEvents";

function Urls(props) {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home {...props} />} />
          <Route path="/home" element={<Home {...props} />} />
          <Route path="/about" element={<About {...props} />} />
          <Route path="/schools" element={<Schools {...props} />} />
          <Route path="/admissions" element={<Admissions {...props} />} />
          <Route path="/vision" element={<Vision {...props} />} />
          <Route path="/academics" element={<Academics {...props} />} />
          <Route path="/news-events" element={<NewsEvents {...props} />} />
          <Route path="/news/:slug" element={<NewsItem {...props} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Urls;
