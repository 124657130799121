import { Box, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import scrollOffset from "../../utils/ScrollOffset";
// import { Link } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
export default function HeroImage(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checked, setChecked] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleClick = () => {
    console.log("clicked me");
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition < scrollOffset) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [scrollPosition]);

  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      <Box
        component="img"
        src={props.image}
        sx={{
          height: { xs: "90vh", md: "auto" },
          width: { xs: "auto", md: "100vw" },
          position: "relative",
          maxHeight: { md: "90vh" },
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      {checked ? (
        <Grid container sx={{ justifyContent: "center", bottom: "3em" }}>
          <Grid item>
            <Link to="home-start" smooth={true} offset={-75}>
              <ExpandMoreIcon sx={{ color: "#fe454a", fontSize: "6em" }} />
            </Link>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
