import MainHeader from "../components/AppBar";
import Footer from "../components/utils/Footer";
import { useEffect, useState, useRef, useCallback } from "react";
import MapController from "../components/map/MapView";
import { Box, Grid, Typography } from "@mui/material";
import MapSidebarInfo from "../components/map/MapSidebarInfo";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { createContext, useContext } from "react";
import axios from "axios";
import SchoolSearch from "../components/map/SchoolSearch";
import MapDrawer from "../components/map/MapDrawer";
import MapSelectFab from "../components/map/MapSelectFab";
import k8 from "../icons/k8.png";
import ps from "../icons/ps.png";
import ms from "../icons/ms.png";
import hs from "../icons/hs.png";
import k12 from "../icons/k_12.png";
import { Translator, Translate } from "react-auto-translate";
import useWindowDimensions from "../components/utils/UseWindowDimensions";
mapboxgl.accessToken = process.env["REACT_APP_MAPBOX_ID"];

export const MapContext = createContext(null);

export default function Schools(props) {
  const globalProps = props.globalProps;

  const [lng, setLng] = useState(-73.961176);
  const [lat, setLat] = useState(40.798022);
  const [zoom, setZoom] = useState(13);
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [schoolData, setSchoolData] = useState();
  const [school, setSchool] = useState();
  const [levels, setSchoolLevels] = useState();
  const [quals, setQuals] = useState();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [checked, setChecked] = useState(true);
  const { height, width } = useWindowDimensions();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env["REACT_APP_MAP_STYLE"],
      center: [lng, lat],
      zoom: zoom,
    });
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    const moods = {
      k8: k8,
      ps: ps,
      ms: ms,
      hs: hs,
      k12: k12,
    };
    Object.keys(moods).forEach((mood, ix) => {
      map.current.loadImage(moods[mood], (error, image) => {
        if (error) throw error;
        map.current.addImage(`${mood}-icon`, image, { sdf: true });
      });
    });

    map.current.on("load", () => {
      map.current.resize();
      var lyr = map.current.getLayer("schools");
      if (lyr === undefined) {
        map.current.addSource("schools", {
          type: "geojson",
          data: { type: "FeatureCollection", features: [] },
        });
        map.current.addLayer({
          id: "schools",
          type: "symbol",
          source: "schools",
          layout: {
            "icon-size": 1,
            "icon-image": [
              "match",
              ["get", "sch_type"],
              "Elementary",
              "ps-icon",
              "K-8",
              "k8-icon",
              "Junior High-Intermediate-Middle",
              "ms-icon",
              "6-12",
              "hs-icon",
              "K-12",
              "k12-icon",
              "ms-icon",
            ],
          },
          paint: {
            "icon-color": "#198cc2",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 5,
          },
        });

        map.current.addLayer({
          id: "schools-labels",
          type: "symbol",
          source: "schools",
          minzoom: 15,
          layout: {
            "text-field": ["get", "name"],
            "text-justify": "auto",
            "text-offset": [0, 3],
            "text-size": ["interpolate", ["linear"], ["zoom"], 1, 0, 15, 14],
          },
        });
      }

      map.current.addSource("selectedSchool", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });

      map.current.addLayer({
        id: "selectedSchool",
        type: "circle",
        source: "selectedSchool",
        paint: {
          "circle-radius": 20,
          "circle-color": "#1b8cc3",
          "circle-opacity": 0.4,
        },
      });

      map.current.on("mouseenter", "schools", (e) => {
        map.current.getCanvas().style.cursor = "pointer";

        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const schName = e.features[0].properties.name;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(schName).addTo(map.current);
      });

      map.current.on("mouseenter", "school-locations", (e) => {
        map.current.getCanvas().style.cursor = "pointer";

        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const schName = e.features[0].properties.location_n;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(schName).addTo(map.current);
      });
    });

    map.current.on("mouseleave", "schools", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  }, []);

  useEffect(() => {
    axios
      .post("api/schools/", {})
      .then((res) => {
        setSchoolData(res.data[0].school_data);
        var rawLevels = res.data[0].levels;
        var levels = rawLevels.map(function (item) {
          return item === "Junior High-Intermediate-Middle" ? "Middle" : item;
        });
        setSchoolLevels(levels);
        console.log(levels);
        setQuals(res.data[0].quals);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (map.current && school) {
      const sch = schoolData.filter((i) => i.properties.dbn === school.dbn);
      map.current.flyTo({ center: sch[0].geometry.coordinates });
      map.current.getSource("selectedSchool").setData(sch[0]);
    }
  }, [school]);

  return (
    <Translator
      to={globalProps.lang}
      from="en"
      googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
    >
      <Box>
        <MainHeader {...globalProps} checked={checked} />
        <MapContext.Provider
          value={{
            map: map.current,
            mapContainer: mapContainer,
            school: school,
            setSchool: setSchool,
            schoolData: schoolData,
            schoolLevels: levels,
            quals: quals,
          }}
        >
          <Box sx={{ flexGrow: 0, height: "100vh" }}>
            <Grid container direction={"column"} sx={{ position: "relative" }}>
              {width > 900 ? (
                <Grid item xs={0} md={2} sx={{ marginTop: "75px" }}>
                  <SchoolSearch globalProps={globalProps} />
                </Grid>
              ) : null}
              <Grid item xs={12} md={10}>
                <Grid container spacing={0} sx={{ position: "relative" }}>
                  {width > 900 ? (
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100vh",
                        }}
                      >
                        <MapSidebarInfo
                          settings={globalProps}
                          schoolData={schoolData}
                          setSchoolData={setSchoolData}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <>
                      <MapSelectFab globalProps={globalProps} />
                      <MapDrawer
                        settings={globalProps}
                        schoolData={schoolData}
                        setSchoolData={setSchoolData}
                      />
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={8}>
                    <MapController
                      settings={globalProps}
                      schoolData={schoolData}
                      setSchoolData={setSchoolData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </MapContext.Provider>
      </Box>
    </Translator>
  );
}
