import { Grid, Typography, Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import NewsCard from "./NewsCard";
import { Translator, Translate } from "react-auto-translate";
export default function NewsList(props) {
  const [news, setNews] = useState();
  const [numItems, setNumItems] = useState(props.numItems);
  useEffect(() => {
    axios.get(`api/news/${numItems}`).then((res) => {
      setNews(res.data);
      console.log(res.data);
    });
  }, [numItems]);

  const handleLoadMore = () => {
    setNumItems(numItems + 5);
  };
  return (
    <div>
      <Typography variant="h2" sx={{ textAlign: "left" }}>
        <Translate>Latest News </Translate>
      </Typography>
      {news ? (
        <Grid container direction="column" spacing={0}>
          {news.map((n, i) => (
            <Grid item>
              <NewsCard news={n} />
            </Grid>
          ))}
          <Grid item>
            <Button variant="outlined" onClick={handleLoadMore}>
              Load More
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
