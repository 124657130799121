import { Typography, Box, Button, List, ListItem } from "@mui/material";
import parse, { domToReact } from "html-react-parser";
import { d3Theme } from "../../Theme";
import { Translator, Translate } from "react-auto-translate";
import { Link } from "react-router-dom";

export default function Parse(props) {
  const characterLimit = props.characterLimit;
  const typography = d3Theme.typography;

  const xwalk = {
    // unique additional styles not covered in theme
    strong: { fontWeight: 700 },
    em: { fontStyle: "italic" },
    p: {
      fontWeight: 400,
      fontFamily: `"Hass Grot Disp 55 Roman"`,
    },
  };

  // combine theme rules with the above
  Object.keys(d3Theme.typography).map((i) => {
    xwalk[i] = d3Theme.typography[i];
  });

  const options = {
    replace: (domNode) => {
      if (domNode.children) {
        const compiledText = [];
        const listText = [];
        // listText.push(<List></List>)
        // if there are children elements, push to array as box elements
        // to allow for multiple styles of inline text. may want to add add'l
        // checks in the else clause to check for link vs. text as opposed
        // to assuming the box alone is enough for any child element
        domNode.children.map((c, i) => {
          if (c.data) {
            if (c.parent.name !== "ul") {
              compiledText.push(
                <Box
                  key={`${i}-${Math.random()}`}
                  component="span"
                  sx={{ ...xwalk[c.parent.name], ...props.xs }}
                >
                  <Translate>
                    {c.data} {"in here 5"}
                  </Translate>
                </Box>
              );
            } else if (c.parent.name === "ul") {
              compiledText.push(
                <div>
                  {c.data}
                  {"in here 4"}
                </div>
              );
            }
          } else if (
            c.children.length > 0 &&
            c.name !== "a" &&
            c.name !== "li"
          ) {
            compiledText.push(
              <Box
                key={`${i}-${Math.random()}`}
                component="span"
                sx={{ ...xwalk[c.parent.name], ...props.xs }}
              >
                <Translate>
                  {c.children[0].data} {"in here 1"}
                </Translate>
              </Box>
            );
          } else if (
            c.name === "a" &&
            c.attribs &&
            c.attribs.class === "simple-button-plugin"
          ) {
            compiledText.push(
              <Box
                key={`${i}-${Math.random()}`}
                component="span"
                sx={{ ...xwalk[c.parent.name], ...props.xs }}
              >
                <Button variant="outlined">
                  <a
                    href={c.attribs.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#148bc1", fontWeight: 700 }}
                  >
                    {c.children[0].data} {"in here 3"}
                  </a>
                </Button>
              </Box>
            );
          } else if (c.name === "a") {
            compiledText.push(
              <Box
                key={`${i}-${Math.random()}`}
                component="span"
                sx={{ ...xwalk[c.parent.name], ...props.xs }}
              >
                <a
                  href={c.attribs.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#148bc1", fontWeight: 700 }}
                >
                  {c.children[0].data} {"in here 6"}
                </a>
              </Box>
            );
          } else {
            c.children.filter((i) =>
              i.name === "p"
                ? compiledText.push(
                    <ListItem
                      sx={{
                        ...xwalk[i.children[0].name],
                        ...props.xs,
                      }}
                    >
                      {i.children[0].children[0].data} {"in here instead"}
                    </ListItem>
                  )
                : null
            );
          }
          // console.log(compiledText);
        });

        // return (
        //   <Typography
        //     key={`foo-${Math.floor(Math.random() * 100)}`}
        //     sx={props.sx}
        //   >
        //     {compiledText.map((t) => t)} {"in here 8"}
        //   </Typography>
        // );
      } else {
        // return (
        //   <Typography
        //     key={`foo-${Math.floor(Math.random() * 100)}`}
        //     variant="body1"
        //     sx={props.sx}
        //   >
        //     <Translate>
        //       {domNode.data} {"in here 9"}
        //     </Translate>
        //   </Typography>
        // );
        // trigger rebuild
      }
    },
  };

  return parse(props.html, options);
}
