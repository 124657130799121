import { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Typography } from "@mui/material";
import TeamMember from "./TeamMember";
import { Translator, Translate } from "react-auto-translate";

export default function DistrictTeam(props) {
  const [team, setTeam] = useState();

  useEffect(() => {
    axios.get("api/team/").then((res) => {
      setTeam(res.data);
      //   console.log(res.data);
    });
  }, []);
  return (
    <>
      <Typography variant="h2">
        <Translate>District Team</Translate>
      </Typography>
      {team ? (
        <Grid container id="all-team-grid-container">
          {team.map((i) => (
            <TeamMember member={i} />
          ))}
        </Grid>
      ) : null}
    </>
  );
}
