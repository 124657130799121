import { Container, Typography, Toolbar, Box, Grid, Link } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import FormParent from "./FormParent";
import style from "../../components/Stylesheet.module.css";
import { Translator, Translate } from "react-auto-translate";
import { cacheProvider } from "../../utils/LangCacheProvider";
export default function Footer(props) {
  const globalProps = props.globalProps;
  return (
    <Box
      sx={{
        // marginTop: "8rem",
        padding: "2rem 0 4rem 0",
        flexGrow: 1,
        backgroundColor: "#00567C",
        bottom: 0,
        position: "relative",
        width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{}} id="left-grid-parent">
              <Typography
                variant="h3"
                color="#ffffff"
                sx={{ textAlign: "left" }}
              >
                <Translate>Join Our Mailing List </Translate>
              </Typography>
              <FormParent />
            </Grid>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={4}>
              <Typography
                color="#ffffff"
                variant="h3"
                sx={{ textAlign: "left" }}
              >
                <Translate> Contact Us </Translate>
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", alignItems: "center", py: 1 }}
              >
                <Grid item xs={1}>
                  <HomeIcon sx={{ color: "#ffffff" }} />
                </Grid>
                <Grid item xs={11}>
                  <a
                    href="https://goo.gl/maps/mWg3FzCwfmj579rS9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography variant="body2" color="#ffffff">
                      154 West 93rd Street New York, NY 10025
                    </Typography>
                  </a>
                  {/* the above could link to a google maps page */}
                </Grid>
                <Grid item xs={1}>
                  <LocalPhoneIcon sx={{ color: "#ffffff" }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" color="#ffffff">
                    212-678-5857
                  </Typography>
                </Grid>

                <Grid item xs={1}>
              <Link href="https://www.instagram.com/nycdistrict3/" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon sx={{ color: "#ffffff" }} />
              </Link>                  
                </Grid>
                <Grid item xs={1}>
                  <Link href="https://twitter.com/nycdistrict3" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon sx={{ color: "#ffffff" }} />  
                  </Link>                  
                </Grid>
                <Grid item xs={1}>
                  <FacebookIcon sx={{ color: "#ffffff" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="#ffffff">
                <Translate>Copyright D3 2023 </Translate>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </Box>
  );
}
