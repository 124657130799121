import { Button, ButtonGroup, Typography, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { Translate } from "react-auto-translate";

export default function FilterButton(props) {
  const [selected, setSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);
  const open = Boolean(anchorEl);
  const choice = props.choice;
  const handleButtonClick = (e) => {
    setSelected(!selected);
    props.handleClick(e);
  };

  useEffect(() => {
    if (props.isAllSelected) {
      setSelected(false);
    }
  }, [props.isAllSelected]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const tooltips = {
    DL: "Show schools with Dual Language programs",
    "G&T": "Show schools with Gifted and Talented Program",
    Elementary: "Show Elementary Schools",
    Middle: "Show Middle Schools",
    "K-12": "Show K-12 Schools",
    "6-12": "Show 6-12 Schools",
    "K-8": "Show K-8 Schools",
  };
  return (
    <Button
      variant={selected ? "contained" : "outlined"}
      size="medium"
      onClick={handleButtonClick}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      value={choice}
      sx={{ textTransform: "capitalize" }}
      key={`${choice}-key`}
    >
      <Translate>{choice} </Translate>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          pointerEvents: "none",
        }}
        onClose={handlePopoverClose}
      >
        <Typography sx={{ p: 1 }}>{tooltips[choice]}</Typography>
      </Popover>
    </Button>
  );
}
