import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@emotion/react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import Parse from "../utils/HTMLParser";
import CalendarIcon2 from "./CalendarIcon";
import { Translator, Translate } from "react-auto-translate";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../utils/UseWindowDimensions";

export default function EventCard(props) {
  const [characterLimit, setCharacterLimit] = useState(50);
  const event = props.event;
  const eventDate = new Date(event.event_date_formatted.replace(/-/g, "/"));

  const { height, width } = useWindowDimensions();
  function stripHTML(str, i) {
    var cleanText = str.replace(/<\/?[^>]+(>|$)/g, "");
    var firstText = cleanText.slice(0, characterLimit);
    return (
      <Typography>
        <Translate>{firstText + "..."}</Translate>{" "}
        <Link to={`/news-events`} state={{ id: i }}>
          {<Translate>{"read more >>"}</Translate>}
        </Link>
      </Typography>
    );
  }

  return (
    <Card elevation={0} key={props.key}>
      <CardContent>
        <Grid container direction={width < 900 ? "column" : "row"} spacing={2}>
          <Grid item xs={4}>
            <CalendarIcon2 date={eventDate} />
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "left" }}>
            <Typography variant="h4">
              <Translate>{event.title}</Translate>
            </Typography>
            <Typography sx={{ textAlign: "justify", fontWeight: "bold" }}>
              {event.is_all_day ? (
                <Translate>
                  <Typography>All Day</Typography>
                </Translate>
              ) : event.time_period_start.length > 1 ? (
                <Translate>
                  Times:
                  {event.time_period_start.map((i, e) => (
                    <Typography>
                      {i} - {event.time_period_end[e]}
                    </Typography>
                  ))}
                </Translate>
              ) : (
                <Translate>
                  Time:
                  <Typography>
                    {event.time_period_start[0]}-{event.time_period_end[0]}
                  </Typography>
                </Translate>
              )}
            </Typography>
            <Typography sx={{ textAlign: "justify" }}>
              <Translate>Location: </Translate>
              <Parse html={event.location} />
              {event.content.length <= characterLimit ? (
                <Parse html={event.content} />
              ) : (
                stripHTML(event.content, event.id)
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
