import MainHeader from "../components/AppBar";
import axios from "axios";
import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import Parse from "../components/utils/HTMLParser";
import { Container } from "@mui/system";
import Footer from "../components/utils/Footer";
import HeroImage from "../components/pageElements/HeroImage";
import scrollOffset from "../utils/ScrollOffset";
import EventList from "../components/pageElements/EventList";
import PageLeader from "../components/pageElements/PageLeader";
import LinkBar from "../components/pageElements/LinkBar";
import NewsList from "../components/pageElements/NewsList";
import D3 from "../components/pageElements/D3";
import MissionLogo from "../icons/mission-red.svg";
import SchoolLogo from "../icons/school-red.svg";
import AdmissionsLogo from "../icons/admissions-red.svg";
import { Translator, Translate } from "react-auto-translate";
import { cacheProvider } from "../utils/LangCacheProvider";
import useWindowDimensions from "../components/utils/UseWindowDimensions";
import { Element } from "react-scroll";
export default function Home(props) {
  const globalProps = props.globalProps;
  const [body, setBody] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checked, setChecked] = useState(false);
  const [hero, setHero] = useState(null);
  const { height, width } = useWindowDimensions();
  const links = [
    {
      page: "/about",
      icon: MissionLogo,
      name: "Our Mission",
      alt: "our mission",
    },
    {
      page: "/schools",
      icon: SchoolLogo,
      name: "Our Schools",
      alt: "our schools",
    },
    {
      page: "/admissions",
      icon: AdmissionsLogo,
      name: "Admissions",
      alt: "admission",
    },
  ];
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > scrollOffset) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    axios.get("api/hero/home").then((res) => {
      setHero(res.data.url);
    });
  }, []);

  return (
    <>
      <D3 />
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
        cacheProvider={cacheProvider}
      >
        <Box>
          <MainHeader {...globalProps} checked={checked} />
          <HeroImage image={hero} />
          <Container
            maxWidth={"lg"}
            sx={{ paddingBottom: "3em", paddingTop: "3em" }}
          >
            <Grid container>
              <Element name="home-start">
                <PageLeader page={"home"} globalProps={globalProps} />
              </Element>
            </Grid>
          </Container>

          <LinkBar color={"#fff"} linkButtons={true} links={links} />
          <Container
            maxWidth={"lg"}
            sx={{ paddingBottom: "3em", paddingTop: "3em" }}
          >
            <Grid container>
              <Grid
                container
                sx={{ marginTop: "3em", position: "relative" }}
                spacing={2}
              >
                <Grid item xs={12} sm={8}>
                  <NewsList numItems={2} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EventList />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer globalProps={globalProps} />
      </Translator>
    </>
  );
}
