import { useEffect, useState } from "react";
import { Grid, Box, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Container } from "@mui/material";
import { Translator, Translate } from "react-auto-translate";
import { Link } from "react-router-dom";
export default function LinkBar(props) {
  const color = props.color;
  const linkButtons = props.linkButtons;
  const links = props.links;

  return (
    <Container
      maxWidth={false}
      sx={{
        position: "relative",
        width: "100vw",
        maxWidth: "100vw",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <Box
        sx={{
          // height: "400px",
          backgroundColor: color,
          padding: "0",
        }}
      >
        <Container>
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              maxHeight: "inherit",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            {linkButtons
              ? links.map((l) => (
                  <Grid
                    item
                    // xs={12}
                    // sm={12}
                    md={12}
                    lg={4}
                    sx={{
                      width: "auto",
                      textAlign: { xs: "-webkit-center" },
                    }}
                  >
                    <Card
                      sx={{
                        height: "300px",
                        width: "300px",
                        alignSelf: "center",
                        margin: "3rem",
                        backgroundColor: "#FCDADB",
                        marginTop: "1em",
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                      elevation={0}
                    >
                      <CardContent>
                        <Link
                          to={l.page}
                          style={{
                            textDecoration: "none",
                            color: "#EF484E",
                          }}
                        >
                          <img
                            src={l.icon}
                            style={{
                              height: "180px",
                              width: "auto",
                              margin: "20px",
                            }}
                            alt={l.alt}
                          />
                          <Typography
                            variant="h3"
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.75em",
                              margin: "5px",
                              textTransform: "uppercase",
                            }}
                          >
                            <Translate>{l.name}</Translate>
                          </Typography>
                        </Link>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : links.map((l) => (
                  // not links; the values bar on the admissions page
                  <Grid
                    item
                    // xs={12}
                    // sm={12}
                    md={12}
                    lg={4}
                    sx={{
                      width: "auto",
                      textAlign: { xs: "-webkit-center" },
                    }}
                  >
                    <Card
                      sx={{
                        height: "300px",
                        width: "300px",
                        alignSelf: "center",
                        backgroundColor: "#FCDADB",
                        marginTop: "3em",
                        marginLeft: 0,
                        display: "inline-block",
                      }}
                      elevation={0}
                    >
                      <CardContent>
                        <Link
                          to={l.page}
                          style={{
                            textDecoration: "none",
                            color: "#EF484E",
                          }}
                        >
                          <img
                            src={`https://doe-d3.s3.amazonaws.com/images/${l.icon}`}
                            style={{
                              height: "180px",
                              width: "auto",
                              margin: "20px",
                            }}
                            alt={l.name}
                          />
                          <Typography
                            variant="h3"
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.75em",
                              margin: "5px",
                              textTransform: "uppercase",
                            }}
                          >
                            <Translate>{l.name}</Translate>
                          </Typography>
                        </Link>
                      </CardContent>
                    </Card>
                    <Container
                      sx={{
                        width: "300px",
                        padding: "0px !important",
                        // marginLeft: "0",
                      }}
                    >
                      <Typography mt={2} sx={{ textAlign: "left" }}>
                        <Translate>{l.description}</Translate>
                      </Typography>
                    </Container>
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
