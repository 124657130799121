import { Typography, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import MainHeader from "../components/AppBar";
import EventsCalendar2 from "../components/pageElements/EventsCalendar2";
import NewsFull from "../components/pageElements/NewsFull";
import Footer from "../components/utils/Footer";
import { Translator, Translate } from "react-auto-translate";

export default function NewsEvents(props) {
  const globalProps = props.globalProps;
  return (
    <>
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
      >
        <Box>
          <MainHeader {...globalProps} checked={true} />
          <Container
            maxWidth={"lg"}
            sx={{ paddingBottom: "3em", paddingTop: "7em" }}
          >
            <Typography variant="h2" sx={{ textAlign: "left" }}>
              <Translate> Events Calendar</Translate>
            </Typography>
            <EventsCalendar2 lang={globalProps.lang} />
            <Grid container>
              <Grid item>
                <NewsFull numItems={5} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />
      </Translator>
    </>
  );
}
