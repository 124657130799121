import { Box, Card, CardMedia } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@emotion/react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import Parse from "../utils/HTMLParser";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Translator, Translate } from "react-auto-translate";
import useWindowDimensions from "../utils/UseWindowDimensions";

export default function NewsCard(props) {
  const news = props.news;
  const [body, setBody] = useState();
  // const [width, setWidth] = useState(window.innerWidth);
  const [characterLimit, setCharacterLimit] = useState(100);
  const { height, width } = useWindowDimensions();

  const publishDate = new Date(news.publish_date.replace(/-/g,'/')).toLocaleDateString();

  useEffect(() => {
    if (width < 900) {
      setCharacterLimit(20);
    }
  }, [width]);

  useEffect(() => {
    const parser = new DOMParser().parseFromString(news.content, "text/html");
    var h = [...parser.body.children].map((el) => el.outerHTML);
    setBody(h);
  }, [news]);

  function stripHTML(str, link) {
    var totalLength = 0;
    str.map((s) => (totalLength += s.length));
    if (str.length === 1) {
      var cleanText = str[0].replace(/<\/?[^>]+(>|$)/g, "");
      var firstText = cleanText.slice(0, characterLimit);
      // condition to account for line breaks; if there's a line break, use that instead of / along with character limit
      var firstLine = cleanText.split("</p>");
      if (firstLine.length > 1) {
        return (
          <Typography key={"i"}>
            <Translate>{firstLine[0] + "..."}</Translate>{" "}
            <Link to={`/news/${link}`}>
              {<Translate>{"read more >>"}</Translate>}
            </Link>
          </Typography>
        );
      } else {
        return (
          <Typography key={"i"}>
            <Translate>{firstText + "..."}</Translate>{" "}
            <Link to={`/news/${link}`}>
              {<Translate>{"read more >>"}</Translate>}
            </Link>
          </Typography>
        );
      }
    } else {
      var cleanText = str[0].replace(/<\/?[^>]+(>|$)/g, "");
      var firstText = cleanText.slice(0, characterLimit);
      var firstLine = cleanText.split("</p>");
      if (firstLine.length > 1) {
        return (
          <Typography key={"i"}>
            <Translate>{firstLine[0] + "..."}</Translate>{" "}
            <Link to={`/news/${link}`}>
              {<Translate>{"read more >>"}</Translate>}
            </Link>
          </Typography>
        );
      } else {
        return (
          <Typography key={"i"}>
            <Translate>{firstText + "..."}</Translate>{" "}
            <Link to={`/news/${link}`}>
              {<Translate>{"read more >>"}</Translate>}
            </Link>
          </Typography>
        );
      }
    }
  }

  function checkHTML(body, link) {
    var firstLine = body[0].split("</p>");
    if (firstLine.length > 1) {
      return (
        <Typography key={"i"}>
          <Parse
            key={"mlk"}
            html={firstLine[0]}
            characterLimit={characterLimit}
            contentLink={""}
          />
          <Link to={`/news/${link}`}>
            {<Translate>{"read more >>"}</Translate>}
          </Link>
        </Typography>
      );
    } else {
      return body.map((n, i) => (
        <Parse
          key={i}
          html={n}
          characterLimit={characterLimit}
          contentLink={""}
        />
      ));
    }
  }
  return (
    <div>
      {body && body.length ? (
        <Card
          key={`${news.id}-news-card`}
          sx={{
            display: "flex",
            height: { xs: "450", sm: "250px" },
            margin: { xs: "1em", sm: "2em" },
            textAlign: "left",
          }}
          elevation={2}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 2, md: 1 }}>
              <CardMedia
                component={news.image ? "img" : "div"}
                image={`https://doe-d3.s3.amazonaws.com/images/${news.image}`}
                sx={{
                  // width: { xs: "100%" },
                  // height: { xs: "auto", md: "100%" },
                  height: "fit-content",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", sm: "column" },
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                  }}
                >
                  <Typography variant="h4">
                    <Translate>{news.title}</Translate>
                  </Typography>
                  {news.show_publish_date ? (
                    <Typography variant="h5">{publishDate}</Typography>
                  ) : null}

                  {body[0].length <= characterLimit
                    ? // ? body.map((n, i) => (
                      //     <Parse
                      //       key={i}
                      //       html={n}
                      //       characterLimit={characterLimit}
                      //       contentLink={""}
                      //     />
                      //   )
                      checkHTML(body, news.slug)
                    : stripHTML(body, news.slug)}
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Card>
      ) : null}
    </div>
  );
}
