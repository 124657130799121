import { Button, ButtonGroup, Typography, Skeleton, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import FilterButton from "./FilterButton";
import { Translator, Translate } from "react-auto-translate";

export default function FilterButtons(props) {
  const choices = props.choices;
  const [allSelected, setAllSelected] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const handleAllButtonClick = (e) => {
    setAllSelected(!allSelected);
  };

  const handleChoiceClick = (e) => {
    props.handleClick(e);
    setAllSelected(false);
  };

  useEffect(() => {
    if (props.selected.length === 0) {
      setAllSelected(true);
    }
  }, [props.selected]);

  useEffect(() => {
    if (allSelected) {
      // clears all selection from levels in parent state,
      // for whatever flavor of choices is passed in
      props.setSelected([]);
    }
  }, [allSelected]);

  return (
    <div style={{ paddingBottom: "1em" }}>
      <Typography
        variant="h4"
        sx={{ color: "#FE454A", textAlign: { xs: "center", md: "left" } }}
      >
        <Translate>{props.title} </Translate>
      </Typography>
      {props.group ? (
        choices ? (
          Array.isArray(choices) ? (
            <ButtonGroup
              orientation={width <= 900 ? "vertical" : "horizontal"}
              sx={{
                width: { xs: "-webkit-fill-available", md: "auto" },
                textAlign: "center",
              }}
              size="medium"
            >
              <Button
                variant={allSelected ? "contained" : "outlined"}
                onClick={handleAllButtonClick}
                size="medium"
              >
                <Translate>All</Translate>
              </Button>
              {choices.map((c, i) => (
                <FilterButton
                  choice={c}
                  handleClick={handleChoiceClick}
                  key={i}
                  isAllSelected={allSelected}
                />
              ))}
            </ButtonGroup>
          ) : (
            <div style={{ textAlign: { xs: "center", sm: "left" } }}>
              <ButtonGroup
                sx={{
                  width: { xs: "-webkit-fill-available", md: "auto" },
                  placeContent: { xs: "center", sm: "left" },
                }}
              >
                <Button
                  variant={allSelected ? "contained" : "outlined"}
                  onClick={handleAllButtonClick}
                >
                  <Translate>All</Translate>
                </Button>
                {Object.keys(choices).map((c) => (
                  <FilterButton
                    choice={choices[c]}
                    handleClick={handleChoiceClick}
                    key={c}
                    isAllSelected={allSelected}
                  />
                ))}
              </ButtonGroup>
            </div>
          )
        ) : (
          <Skeleton variant="rounded" width={300} height={40} />
        )
      ) : (
        <>
          <Grid container id="button-parent" spacing={1} columns={4}>
            <Grid item xs="auto">
              <Button
                variant={allSelected ? "contained" : "outlined"}
                onClick={handleAllButtonClick}
                size="medium"
              >
                All
              </Button>
            </Grid>
            {Object.keys(choices).map((c, i) => (
              <Grid item xs="auto" key={`${i}-button`}>
                <FilterButton
                  choice={choices[c]}
                  handleClick={handleChoiceClick}
                  key={c}
                  isAllSelected={allSelected}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}
