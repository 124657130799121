import { useEffect, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import { Translator, Translate } from "react-auto-translate";
import { MapContext } from "../../views/schools";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SchoolCard(props) {
  // for each school, return a card w school info
  const [expanded, setExpanded] = useState(false);
  const sch_data = props.sch.properties;
  const ctx = useContext(MapContext);

  const handleExpandClick = (e) => {
    ctx.setSchool(e);
    console.log(e);
  };

  return (
    <Card sx={{ backgroundColor: "#00567C" }} elevation={1}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2} sx={{ alignSelf: "center" }}>
            {sch_data.image === "" ? (
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                  backgroundColor: "#FE454A",
                  textAlign: "center",
                }}
                aria-label="school-name"
              >
                {sch_data.ps}
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                  objectFit: "cover",
                  backgroundColor: "#FE454A",
                }}
                aria-label="school-name"
                src={`https://doe-d3.s3.amazonaws.com/images/${sch_data.image}`}
              ></Avatar>
            )}
          </Grid>
          <Grid item xs={10} sx={{ textAlign: "left" }}>
            <Typography variant="h3">{sch_data.name}</Typography>
            <Typography color="white">{sch_data.ps}</Typography>
            <Typography color="white">
              <Translate>
                {`Grades: ${sch_data.gradelo} - ${sch_data.gradehi}`}{" "}
              </Translate>
            </Typography>
            <Typography color="white">{sch_data.address}</Typography>
            <Grid container spacing={0}>
              {sch_data.gt_prog ? (
                <Avatar sx={{ backgroundColor: "green", margin: "0.2em" }}>
                  GT
                </Avatar>
              ) : (
                <Avatar
                  sx={{
                    backgroundColor: "#ccc",
                    margin: "0.2em",
                    // opacity: 0.4,
                  }}
                >
                  GT
                </Avatar>
              )}
              {sch_data.dual_lang ? (
                <Avatar sx={{ backgroundColor: "red", margin: "0.2em" }}>
                  DL
                </Avatar>
              ) : (
                <Avatar sx={{ backgroundColor: "#ccc", margin: "0.2em" }}>
                  DL
                </Avatar>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: "right" }}>
        <InfoOutlinedIcon
          fontSize="large"
          sx={{ color: "white" }}
          onClick={(e) => {
            handleExpandClick(sch_data);
          }}
        />
      </CardActions>
    </Card>
  );
}
