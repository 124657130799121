import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function LanguageSelector(props) {
  const globalProps = props;
  const handleChange = (event) => {
    globalProps.setLang(event.target.value);
  };

  return (
    <Box
      arial-label="Dropdown menu that changes page language"
      anchororigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepmounted="true"
      transformorigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="Dropdown menu that changes page language"
          id="language-select"
          value={globalProps.lang}
          onChange={handleChange}
          displayEmpty
          input={<OutlinedInput />}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "4px",
            height: "35px",
            backgroundColor: "white",
          }}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Español</MenuItem>
          <MenuItem value={"zh-CN"}>中文</MenuItem>
          <MenuItem value={"bn"}>বাংলা</MenuItem>
          <MenuItem value={"ru"}>русский</MenuItem>
          <MenuItem value={"ur"} dir="rtl">
            اردو
          </MenuItem>
          <MenuItem value={"ar"} dir="rtl">
            عربي
          </MenuItem>
          <MenuItem value={"ht"}>Kreyòl ayisyen</MenuItem>
          <MenuItem value={"ko"}>한국어</MenuItem>
          <MenuItem value={"fr"}>Français</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
