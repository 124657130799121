import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import NewsCard from "./NewsCard";
import FilterButtons from "../map/FilterButtons";
import { Translator, Translate } from "react-auto-translate";

export default function NewsFull(props) {
  const [news, setNews] = useState();
  const [numItems, setNumItems] = useState(props.numItems);
  const [query, setQuery] = useState({ num_entries: numItems });
  const [tags, setTags] = useState();
  const [queryTags, setQueryTags] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");
  const [searchMessage, setSearchMessage] = useState("");

  const handleTagSelect = (e) => {
    if (queryTags.some((q) => q === e.target.value)) {
      var qs = queryTags.filter((i) => i !== e.target.value);
      setQueryTags(qs);
    } else {
      setQueryTags([...queryTags, e.target.value]);
    }
  };

  useEffect(() => {
    axios
      .get("/api/tags/")
      .then((res) => {
        const t = res.data.map((r) => r.name);
        setTags(t);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    // if tags are selected, update query as {tags:[array, of, tags]}
    if (queryTags.length > 0) {
      setQuery({ tag: queryTags });
    } else {
      // else, show the previous default n number of items
      setQuery({ num_entries: numItems });
    }
  }, [queryTags]);

  useEffect(() => {
    console.log(`searching for ${numItems} items`);
    axios
      .post(
        `api/news/${numItems}`,
        query // updated based on button states
      )
      .then((res) => {
        setNews(res.data);
      });
  }, [query, numItems]);

  const handleSearchClick = () => {
    axios
      .post("api/news_search/", { search_terms: searchTerms })
      .then((res) => {
        if (res.data.length > 0) {
          setNews(res.data);
          console.log(res.data);
          setSearchMessage(`Search results based on '${searchTerms}'`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleLoadMore = () => {
    setNumItems(numItems + 5);
    console.log(numItems);
  };
  const handleClearSearchClick = () => {
    setQuery({ num_entries: numItems });
    setSearchMessage();
    setSearchTerms("");
  };

  useEffect(() => {
    console.log(searchTerms);
  }, [searchTerms]);

  return (
    <div style={{ paddingTop: "3em" }}>
      {searchMessage ? (
        <>
          <Grid container direction={"row"} spacing={2}>
            <Grid item>
              <Typography variant="h2" sx={{ textAlign: "left" }}>
                {searchMessage}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClearSearchClick}>
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          <Translate>Latest News </Translate>
        </Typography>
      )}
      <Grid container direction={"row"}>
        <Grid item xs={12} sm={12} md={8} order={{ xs: 2, md: 1 }}>
          {news && news !== "No results" ? (
            <Grid container>
              {news.map((n, i) => (
                <Grid item xs={12} key={i}>
                  <NewsCard news={n} key={`${i}-card`} />
                </Grid>
              ))}
              <Grid item>
                <Button variant="outlined" onClick={handleLoadMore}>
                  Load More
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sx={{ minWidth: "50vw" }}>
                <Typography>
                  No results found for '{searchTerms}'. Please try another
                  search.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={4} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" sx={{ color: "#FE454A", textAlign: "left" }}>
            <Translate>Search in News Articles</Translate>
          </Typography>
          <Grid
            container
            spacing={0}
            direction="row"
            sx={{ paddingBottom: "1em" }}
          >
            <Grid item xs={9} sx={{ justifyItems: "center" }}>
              <TextField
                value={searchTerms}
                fullWidth
                onChange={(e) => setSearchTerms(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={handleSearchClick}
                sx={{ height: "100%" }}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          {tags ? (
            <FilterButtons
              title={"Filter by Topic"}
              choices={tags}
              selected={queryTags}
              setSelected={setQueryTags}
              handleClick={handleTagSelect}
              group={false}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
