import { Grid, Typography, Card, CardContent } from "@mui/material";
import { Translator, Translate } from "react-auto-translate";

const defaultOptions = {
  header: {
    weekday: "long",
  },
  footer: {
    month: "short",
  },
  value: {
    day: "2-digit",
  },
  year: {
    year: "numeric",
  },
  locale: [],
};

function formatDate(date, locale, formatOptions) {
  return date.toLocaleDateString("en-US", formatOptions);
}

export default function CalendarIcon2(props) {
  const date = props.date;
  // console.log(props);
  const day = formatDate(date, [], defaultOptions.value);
  const month = formatDate(date, [], defaultOptions.footer);
  const year = formatDate(date, [], defaultOptions.year);
  // console.log(day);
  return (
    <Card sx={{ backgroundColor: "#FE454A" }} elevation={0}>
      <CardContent sx={{ padding: "0.5em" }}>
        <Grid container direction={"column"}>
          <Grid item>
            <Typography variant="h4" color="white">
              <Translate>{month}</Translate>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" color="white">
              <Translate>{day}</Translate>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" color="white">
              <Translate>{year}</Translate>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
