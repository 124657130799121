import MainHeader from "../components/AppBar";
import Footer from "../components/utils/Footer";
import { useEffect, useState } from "react";
import PageLeader from "../components/pageElements/PageLeader";
import { Container, Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import FAQs from "../components/pageElements/FAQs";
import { Translator, Translate } from "react-auto-translate";
export default function Admissions(props) {
  const globalProps = props.globalProps;
  return (
    <>
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
      >
        <Box sx={{ marginTop: "50px" }}>
          <MainHeader {...globalProps} checked={true} />
          <Container
            maxWidth={"lg"}
            sx={{ paddingBottom: "3em", paddingTop: "3em" }}
          >
            <Grid container id="about-grid-root">
              {/* <PageLeader page={"admissions"} globalProps={globalProps} /> */}
              <Typography variant="h2" sx={{ textAlign: "left", p: 2 }}>
                {/* <Translate>Process</Translate> */}
              </Typography>
              <PageLeader
                page={"admissions-portal"}
                globalProps={globalProps}
              />
            </Grid>
            <FAQs />
          </Container>
        </Box>
        <Footer />
      </Translator>
    </>
  );
}
