import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";

import { MapContext } from "../../views/schools";
import { Box } from "@mui/material";
import SchoolLegend from "./SchoolIconLegend";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default function MapController(props) {
  const ctx = useContext(MapContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ctx.map && props.schoolData) {
      ctx.map.on("load", () => {
        var data = { type: "FeatureCollection", features: props.schoolData };
        ctx.map.getSource("schools").setData(data);
      });

      ctx.map.on("click", "schools", (e) => {
        ctx.setSchool(e.features[0].properties);
      });
    }
  }, [props.schoolData]);

  return (
    <Box
      xs={12}
      sm={12}
      sx={{
        height: { xs: "100vh", md: "calc(100vh - 287px)" },
        position: "fixed",
        flexDirection: "column",
        width: "-moz-available",
        width: "-webkit-fill-available",
        width: "stretch",
        overflow: "hidden",
      }}
      className="map-container"
      ref={ctx.mapContainer}
    >
      <SchoolLegend />
    </Box>
  );
}
