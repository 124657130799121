import { Typography, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import Parse from "../utils/HTMLParser";
import { Translate } from "react-auto-translate";

export default function FAQs() {
  const [faqs, setFaqs] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    axios
      .get(`api/faqs/`)
      .then((res) => {
        res.data.filter((i) =>
          i.visible === true ? setActive(true) : setActive(false)
        );
        setFaqs(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      {active ? (
        <Grid container sx={{ textAlign: "left", p: 2 }}>
          <Typography variant="h2">
            <Translate>Frequently Asked Questions (FAQs)</Translate>
          </Typography>

          <Typography>
            <Translate>
              Below are the primary questions the D3 team has received so far.
              If you have specific questions, please reach out to us at
            </Translate>{" "}
            contact@doed3.org.
          </Typography>
          {faqs
            ? faqs.map((f, i) => (
                <Grid item xs={12} sx={{ marginTop: "1em" }} key={i}>
                  <Typography variant="h6" color={"#148bc1"}>
                    <Translate>&#x2022; {f.question}</Translate>
                  </Typography>
                  <Parse sx={{ pl: 1.5 }} html={f.answer} />
                </Grid>
              ))
            : null}
        </Grid>
      ) : null}
    </>
  );
}
