import MainHeader from "../components/AppBar";
import Footer from "../components/utils/Footer";
import { useEffect, useState } from "react";
export default function Academics(props) {
  const globalProps = props.globalProps;
  return (
    <>
      <MainHeader {...globalProps} />
      <Footer />
    </>
  );
}
