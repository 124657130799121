import MainHeader from "../components/AppBar";
import { useEffect, useState } from "react";
import Footer from "../components/utils/Footer";
import { Container, Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import HeroImage from "../components/pageElements/HeroImage";
import Parse from "../components/utils/HTMLParser";
import scrollOffset from "../utils/ScrollOffset";
import axios from "axios";
import PageLeader from "../components/pageElements/PageLeader";
import EquityIcon from "../icons/equity.svg";
import EmpowermentIcon from "../icons/empowerment.svg";
import ExcellenceIcon from "../icons/excellence.svg";
import LinkBar from "../components/pageElements/LinkBar";
import InitiativesGrid from "../components/pageElements/InitiativesGrid";
import DistrictTeam from "../components/pageElements/DistrictTeam";
import { Translator, Translate } from "react-auto-translate";

export default function About(props) {
  const globalProps = props.globalProps;
  const [checked, setChecked] = useState(true);
  const [body, setBody] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hero, setHero] = useState(null);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    axios.get("api/values/").then((res) => {
      console.log(res.data);
      setLinks(res.data);
    });
  }, []);

  // const links = [
  //   {
  //     img: EquityIcon,
  //     text: "Equity",
  //     subtext: `Sed ut perspiciatis unde omnis
  //     iste natus error sit voluptatem
  //     accusantium iste doloremque
  //     laudantium, totam rem aperiam
  //     eaque ipsa, quae ab illo`,
  //     alt: "our mission",
  //   },
  //   {
  //     img: EmpowermentIcon,
  //     text: "Empowerment",
  //     subtext: `Sed ut perspiciatis unde omnis
  //     iste natus error sit voluptatem
  //     accusantium iste doloremque
  //     laudantium, totam rem aperiam
  //     eaque ipsa, quae ab illo`,
  //     alt: "our schools",
  //   },
  //   {
  //     img: ExcellenceIcon,
  //     text: "Excellence",
  //     subtext: `Sed ut perspiciatis unde omnis
  //     iste natus error sit voluptatem
  //     accusantium iste doloremque
  //     laudantium, totam rem aperiam
  //     eaque ipsa, quae ab illo`,
  //     alt: "admission",
  //   },
  // ];
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    axios.get("api/hero/about").then((res) => {
      setHero(res.data.url);
    });
  }, []);

  return (
    <>
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
      >
        <Box sx={{ marginTop: "50px" }}>
          <MainHeader {...globalProps} checked={checked} />
          <Container maxWidth={"lg"} sx={{ paddingTop: "3em" }}>
            <Grid container id="about-grid-root">
              <PageLeader page={"about"} globalProps={globalProps} />
              <Typography variant="h2" sx={{ paddingTop: "1em" }}>
                <Translate>Values</Translate>
              </Typography>
            </Grid>
          </Container>
          <LinkBar color={"#fff"} linkButtons={false} links={links} />
          <Container
            maxWidth={"lg"}
            sx={{
              paddingBottom: "3em",
              paddingTop: "3em",
              position: "relative",
              marginTop: "3em",
            }}
          >
            <Grid container>
              <InitiativesGrid />
            </Grid>
            <Grid container sx={{ py: "3em" }}>
              <DistrictTeam />
            </Grid>
          </Container>
        </Box>
        <Footer />
      </Translator>
    </>
  );
}
