import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useRef, useEffect, useState, useContext } from "react";
import { MapContext } from "../../views/schools";
import SchoolCard from "./SchoolCard";
import CloseIcon from "@mui/icons-material/Close";
import { textAlign } from "@mui/system";
import { Translator, Translate } from "react-auto-translate";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";

const padding = { padding: "0 1rem 0 1rem" };

export default function MapSidebarInfo(props) {
  const lang = props.lang;
  const globalProps = props.settings;
  const ctx = useContext(MapContext);

  const handleClose = () => {
    ctx.setSchool();
    ctx.map
      .getSource("selectedSchool")
      .setData({ type: "FeatureCollection", features: [] });
  };
  console.log(ctx.school);
  return (
    <Box
      sx={{
        overflow: "auto",
        overflowY: "scroll",
        maxHeight: { xs: "100vh", md: "calc(100vh - 287px)" },
        height: "calc(100vh - 287px)",
        backgroundColor: "#00567C",
      }}
      flex={1}
      id="sidebar-info"
    >
      {ctx.school ? (
        <div style={{ backgroundColor: "#00567C", height: "100%" }}>
          <Grid container direction="column" sx={{ color: "white" }}>
            <Grid item sx={{ justifyContent: "center", margin: 0 }}>
              <Button onClick={handleClose} sx={{ margin: 0 }}>
                <ArrowBackOutlinedIcon
                  sx={{ color: "white" }}
                  fontSize="medium"
                />
                <Typography
                  sx={{
                    color: "white",
                    marginBottom: 0,
                    transform: "translate(0%, 5%)",
                    p: "0.5em",
                  }}
                >
                  <Translate>Back to schools list</Translate>
                </Typography>
              </Button>
            </Grid>
            <Grid item sx={{ textAlign: "left", px: "1em" }}>
              <Typography variant="h2">{ctx.school.name}</Typography>

              <Button sx={{ paddingLeft: 0, paddingTop: "3px" }}>
                <a
                  href={`${ctx.school.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "white",
                      fontWeight: 700,
                      marginBottom: "1em",
                      textDecoration: "underline",
                    }}
                  >
                    <Translate>Visit Website</Translate>
                  </Typography>
                  <ArrowOutwardIcon sx={{ color: "white" }} fontSize="medium" />
                </a>
              </Button>
              <Box component={"span"}>
                <Typography variant="h5">
                  <Translate>Grades served: </Translate>
                </Typography>
                <Typography variant="h5" fontWeight="300">
                  <Translate> {ctx.school.grade_range}</Translate>
                </Typography>
              </Box>
              <Typography variant="h5">
                <Translate>Address:</Translate>
              </Typography>
              <Typography variant="h5" fontWeight="300">
                {ctx.school.address}
              </Typography>

              <Typography variant="h5">
                <Translate>Contact Phone:</Translate>
              </Typography>
              <Typography variant="h5" fontWeight="300">
                {ctx.school.contact_phone}{" "}
              </Typography>

              <Typography variant="h5">
                <Translate>Description: </Translate>
              </Typography>
              <Typography variant="h5" fontWeight="300">
                <Translate>{ctx.school.description} </Translate>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </div>
      ) : props.schoolData ? (
        <div>
          <Grid container display={"block"} spacing={2}>
            {props.schoolData.map((sch, i) => (
              <Grid item key={`${i}-school-grid-item`}>
                <SchoolCard sch={sch} key={`${i}-school-card`} />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </Box>
  );
}
