// import logo from "./logo.svg";
import "./App.css";
import Urls from "./Urls";
import { ThemeProvider } from "@emotion/react";
import { d3Theme } from "./Theme";
import { useState, useEffect } from "react";
import axios from "axios";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  // const [ip, setIP] = useState(undefined);
  const [lang, setLang] = useState("en");
  const textDir = { en: "ltr", es: "ltr", ar: "rtl", vn: "ltr", hc: "ltr" }[
    lang
  ];

  const globalProps = {
    lang: lang,
    setLang,
  };
  console.log(globalProps);
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <ThemeProvider theme={d3Theme}>
        <ParallaxProvider>
          <Urls globalProps={globalProps} textDir={textDir} />
        </ParallaxProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
