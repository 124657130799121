import { createTheme } from "@mui/material/styles";
// import "./fonts/Haas Grot Text 55 Roman.otf";
export const d3Theme = createTheme({
  direction: "rtl",
  palette: {
    mode: "light",
    primary: {
      vlight: "#e3f2fd",
      light: "#d6edff",
      main: "#00567C",
    },
    secondary: {
      light: "#fcefd9",
      main: "#FE454A",
    },
  },
  typography: {
    h1: {
      fontWeight: 900,
      fontSize: "4rem",
      marginBottom: "1rem",
      color: "#FE454A",
      fontFamily: `"Hass Grot Disp 75 Bold"`,
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      marginBottom: "1rem",
      color: "#FE454A",
      fontFamily: `"Hass Grot Disp 75 Bold"`,
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
      marginTop: "0.75rem",
      marginBottom: "0.5rem",
      color: "#FE454A",
      fontFamily: `"Hass Grot Disp 75 Bold"`,
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
      marginBottom: "0.5rem",
      fontFamily: `"Hass Grot Disp 75 Bold"`,
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.25rem",
      marginBottom: "0.5rem",
      // fontFamily: `"Hass Grot Disp 75 Bold"`,
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
      marginBottom: "0.2rem",
      fontFamily: `"Hass Grot Disp 55 Roman"`,
    },

    body1: {
      fontWeight: 400,
      fontFamily: `"Hass Grot Disp 55 Roman"`,
    },
    bold: {
      fontWeight: 700,
    },
    button: {
      textTransform: "none",
    },
    whiteLink: {
      textTransform: "capitalize",
      fontWeight: 700,
      margin: 0,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
    Toolbar: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
    },
  },
});
