import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import Parse from "../utils/HTMLParser";
import Carousel from "react-material-ui-carousel";
import { Translator, Translate } from "react-auto-translate";
import { Link } from "react-router-dom";

export default function PageLeader(props) {
  const [title, setTitle] = useState();
  const [img, setImg] = useState();
  const [body, setBody] = useState();
  const [ready, setReady] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);
  const [link, setLink] = useState();
  const pageRef = props.page;
  const globalProps = props.globalProps;

  useEffect(() => {
    axios
      .get(`api/page_content/${pageRef}`)
      .then((res) => {
        console.log(res);
        // setTitle(res.data.title);
        setImg(res.data.cropped_image);
        setFullWidth(res.data.full_width);
        setLink(res.data.link_url);
        const parser = new DOMParser().parseFromString(
          res.data.text,
          "text/html"
        );
        var h = [...parser.body.children].map((el) => el.outerHTML);
        setBody(h);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (body) {
      setReady(true);
    }
  }, [body]);

  useEffect(() => {}, [globalProps.lang]);

  const getMeta = (url) => {
    // for use in setting the objectFit property for images
    const img = new Image();
    img.src = url;
    if (img.naturalHeight > img.naturalWidth) {
      return "none";
    } else {
      return "cover";
    }
  };

  if (!ready) return;
  return (
    // implement skeleton here and throughout
    <div style={{ width: "100%" }}>
      <Translator
        to={globalProps.lang}
        from="en"
        googleApiKey="AIzaSyA0AsiQNRMiM4eGBn5WdmCwD2wyRaHHCHE"
      >
        {fullWidth === false ? (
          <Grid
            container
            spacing={6}
            sx={{
              paddingTop: { md: "3em" },
              padding: { xs: "1em" },
              minHeight: "50vh",
            }}
            id="pg-lead-grid-root"
          >
            <Grid item xs={12} sm={6} id="pg-lead-text-grid">
              <Typography variant="h2" sx={{ textAlign: "left" }}>
                <Translate>{title}</Translate>
              </Typography>
              {body.map((b, i) => (
                <Parse html={b} sx={{ textAlign: "left" }} k={i} />
              ))}
            </Grid>
            <Grid item xs={12} sm={6} id="pg-lead-img-grid">
              {img.length > 1 ? (
                <Carousel>
                  {img.map((i, k) => (
                    <Box
                      component="img"
                      src={i}
                      sx={{
                        position: "relative",
                        margin: "auto",
                        height: "100%",
                        width: "auto",
                        maxHeight: "500px",
                        // aspectRatio: 1,
                        transform: { xs: "translate(-25%, 0)", md: 0 },
                        minHeight: "25vh",
                        // objectFit: getMeta(i),
                      }}
                      key={k}
                    />
                  ))}
                </Carousel>
              ) : link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <Box
                    component="img"
                    src={img[0]}
                    sx={{
                      height: { xs: "auto" },
                      maxHeight: "500px",
                      maxWidth: { xs: "100%", md: "30vw" },
                      position: "relative",
                    }}
                  />
                </a>
              ) : (
                <Box
                  component="img"
                  src={img[0]}
                  sx={{
                    height: { xs: "auto" },
                    maxHeight: "500px",
                    maxWidth: { xs: "100%", md: "30vw" },
                    position: "relative",
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={6} sx={{ paddingTop: "3em" }} key={"foo"}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{ textAlign: "left" }}>
                <Translate>{title} </Translate>
              </Typography>
              {body.map((b, i) => (
                <Parse html={b} sx={{ textAlign: "left" }} k={i} />
              ))}
            </Grid>
          </Grid>
        )}
      </Translator>
    </div>
  );
}
