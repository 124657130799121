import { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Typography } from "@mui/material";
import InitaitiveCard from "./InitaitiveCard";
import { Translator, Translate } from "react-auto-translate";

export default function InitiativesGrid(props) {
  const [initiatives, setIniatiaties] = useState();

  useEffect(() => {
    axios.get("api/initiatives/").then((res) => {
      setIniatiaties(res.data);
    });
  }, []);

  return (
    <>
      <Typography variant="h2">
        <Translate>Core Initiatives</Translate>
      </Typography>
      {initiatives ? (
        <Grid container>
          {initiatives.map((i) => (
            <InitaitiveCard initiative={i} />
          ))}
        </Grid>
      ) : null}
    </>
  );
}
