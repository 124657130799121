import { Grid } from "@mui/material";
import Parse from "../utils/HTMLParser";
import { Card, CardContent, CardMedia } from "@mui/material";
import { Typography } from "@mui/material";
import { Translator, Translate } from "react-auto-translate";

export default function InitaitiveCard(props) {
  const init = props.initiative;

  const styles = {
    card: {
      position: "relative",
      margin: "0.5em",
    },
    overlay: {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      color: "white",
      padding: "0.25em",
      backgroundColor: "#00567C",
    },
  };

  return (
    <Grid item xs={12} sm={6}>
      <Card sx={{ margin: "1em", textAlign: "left" }} elevation={0}>
        <CardMedia
          sx={{
            height: { xs: "auto", md: "500px" },
            aspectRatio: { xs: "1/1", md: "auto" },
          }}
          image={`https://doe-d3.s3.amazonaws.com/images/${init.image}`}
        ></CardMedia>
        <CardContent
          sx={{
            backgroundColor: "#FE454A",
            color: "white",
            minHeight: "292px",
          }}
        >
          <Typography variant="h3" color="white">
            <Translate>{init.title} </Translate>
          </Typography>
          <Parse html={init.content} />
        </CardContent>
      </Card>
    </Grid>
  );
}
